import CryptoJS from 'crypto-js'
function EncryptAES (text, pass) {
  var salt = CryptoJS.lib.WordArray.random(128/8);
  
  var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: 256 / 32,
      iterations: 100
    });

  var iv = CryptoJS.lib.WordArray.random(128/8);
  
  var encrypted = CryptoJS.AES.encrypt(text, key, { 
    iv: iv, 
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
    
  });
  var transitmessage = salt.toString()+ iv.toString() + encrypted.toString();
  return transitmessage;
}

function DecryptAES (text, pass) {
  if (!text) return;
  var salt = CryptoJS.enc.Hex.parse(text.substr(0, 32));
  var iv = CryptoJS.enc.Hex.parse(text.substr(32, 32))
  var encrypted = text.substring(64);
  
  var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: 256 / 32,
      iterations: 100
    });

  var decrypted = CryptoJS.AES.decrypt(encrypted, key, { 
    iv: iv, 
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
    
  })
  return decrypted.toString(CryptoJS.enc.Utf8);
}

function GenerateToken() {
    return CryptoJS.lib.WordArray.random(512 / 8).toString(CryptoJS.enc.Base64);
  }

export { EncryptAES, DecryptAES, GenerateToken };