import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import DemoLayout from "src/pages/Dashboard/Layout/DemoLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
import { GetUser } from "../services/user";

// Page Headers
const DashboardHeader = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/DashboardHeader.vue"
  );

  const DemoHeader = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Demo/DemoHeader.vue"
  );

// Dashboard pages
const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/Dashboard.vue"
  );
const Property = () =>
  import(
    /* webpackChunkName: "property" */ "src/pages/Dashboard/Dashboard/Property.vue"
  );
const Motor = () =>
  import(
    /* webpackChunkName: "property" */ "src/pages/Dashboard/Dashboard/Motor.vue"
  );
const Land = () =>
  import(
    /* webpackChunkName: "property" */ "src/pages/Dashboard/Dashboard/Land.vue"
  );
const Vin = () =>
  import(
    /* webpackChunkName: "property" */ "src/pages/Dashboard/Dashboard/Vin.vue"
  );

  const PropertyDemo = () =>
  import(
    /* webpackChunkName: "property" */ "src/pages/Dashboard/Demo/Property.vue"
  );
const MotorDemo = () =>
  import(
    /* webpackChunkName: "property" */ "src/pages/Dashboard/Demo/Motor.vue"
  );
const VinDemo = () =>
  import(
    /* webpackChunkName: "property" */ "src/pages/Dashboard/Demo/Vin.vue"
  );
const Unlock = () => import("src/pages/Dashboard/Dashboard/Unlock.vue");
const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  {
    component: DashboardLayout,
    path: "/",
    redirect: "/unlock",
    children: [
      {
        name: "Unlock",
        path: "/unlock/:id?",
        props: {
          default: route => {
            return {
              id: route.params.id
            };
          }
        },
        meta: {
          title:
            '<h3 class="display-4 p-0 m-0" style="word-break: break-all">HUEstimates.Cost</h3> <h4 class="lead p-0 m-0">Unlock features</h4>'
        },
        components: { default: Unlock, header: DashboardHeader }
      }
    ]
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    name: "Dashboard",

    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        meta: {
          title:
            '<h3 class="display-4 p-0 m-0" style="word-break: break-all">HUEstimates.Cost</h3> <h4 class="lead p-0 m-0">Market price predictions </h4>'
        },
        components: { default: Dashboard, header: DashboardHeader }
      },

      {
        path: "property",
        name: "Property",
        meta: {
          title:
            '<h3 class="display-4 p-0 m-0" style="word-break: break-all">HUEstimates.Cost</h3> <h4 class="lead p-0 m-0">New building appartments cost prediction </h4>'
        },
        components: { default: Property, header: DashboardHeader }
      },
      {
        path: "motor",
        name: "Motor",
        meta: {
          title:
            '<h3 class="display-4 p-0 m-0" style="word-break: break-all">HUEstimates.Cost</h3> <h4 class="lead p-0 m-0">Car price prediction </h4>',
          requiresAuth: 1
        },
        components: { default: Motor, header: DashboardHeader }
      },
      {
        path: "land",
        name: "Land",
        meta: {
          title:
            '<h3 class="display-4 p-0 m-0" style="word-break: break-all">HUEstimates.Cost</h3> <h4 class="lead p-0 m-0">Land for sale price prediction </h4>'
        },
        components: { default: Land, header: DashboardHeader }
      },
      {
        path: "vin",
        name: "Vin",
        meta: {
          title:
            '<h3 class="display-4 p-0 m-0" style="word-break: break-all">HUEstimates.Cost</h3> <h4 class="lead p-0 m-0">VIN lookup </h4>',
          requiresAuth: 1
        },
        components: { default: Vin, header: DashboardHeader }
      }
    ]
  },
  {
    path: "/",
    component: DemoLayout,

    children: [
      {
        path: "property-demo",
        name: "PropertyDemo",
        meta: {
          title:
            '<h3 class="display-4 p-0 m-0" style="word-break: break-all">HUEstimates.Cost</h3> <h4 class="lead p-0 m-0">New building appartments cost prediction </h4>'
        },
        components: { default: PropertyDemo, header: DemoHeader }
      },
      {
        path: "motor-demo",
        name: "MotorDemo",
        meta: {
          title:
            '<h3 class="display-4 p-0 m-0" style="word-break: break-all">HUEstimates.Cost</h3> <h4 class="lead p-0 m-0">Car price prediction </h4>',
        },
        components: { default: MotorDemo, header: DemoHeader }
      },
      {
        path: "vin-demo",
        name: "VinDemo",
        meta: {
          title:
            '<h3 class="display-4 p-0 m-0" style="word-break: break-all">HUEstimates.Cost</h3> <h4 class="lead p-0 m-0">VIN lookup</h4>',
        },
        components: { default: VinDemo, header: DemoHeader }
      },
    ]
  },
  { path: "*", component: NotFound }
];

export default routes;
