// const api =
//   process.env.NODE_ENV === "production"
//     ? "https://api.huestimates.com/app/cost/controller"
//     : "http://localhost:7710/controller";

const api = "https://cost.huestimates.app/api/controller";

function ShowLink(value) {
  if (!value) return;
  var text = value;
  var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  var text1 = text.replace(exp, "<a href='$1'>$1</a>");
  var exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  return text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
}

function HtmlNewLine(value) {
  if (!value) return;
  return value.replace(/(?:\r\n|\r|\n)/g, "<br />");
}

function DateToString(dt) {
  dt = new Date(dt);
  let date = ("0" + dt.getDate()).slice(-2);

  let month = ("0" + (dt.getMonth() + 1)).slice(-2);

  let year = dt.getFullYear();

  return year + "-" + month + "-" + date;
}

function DateTimeToString(dt) {
  dt = new Date(dt);
  let date = ("0" + dt.getDate()).slice(-2);

  let month = ("0" + (dt.getMonth() + 1)).slice(-2);

  let year = dt.getFullYear();

  let hour = dt.getHours().toString().padStart(2, "0");

  let minutes = dt.getMinutes().toString().padStart(2, "0");

  let seconds = dt.getSeconds().toString().padStart(2, "0");

  return (
    year + "-" + month + "-" + date + " " + hour + ":" + minutes + ":" + seconds
  );
}

export {
  ShowLink,
  HtmlNewLine,
  DateToString,
  DateTimeToString,
  api
};