import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./dashboard-plugin";

// Plugins
import App from "./App.vue";

// router setup
import routes from "./routes/routes";

import numeral from "numeral";
import "nprogress/nprogress.css";
import store from "./store";
import VueCookies from "vue-cookies";
// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(VueCookies);

// Vue.prototype.$apiUrl = process.env.NODE_ENV === "production"
// ? "https://cost.huestimates.app/api/controller"
// : "http://localhost:7700/controller"

Vue.prototype.$apiUrl = "https://cost.huestimates.app/api/controller"
  // process.env.NODE_ENV === "production"
  //   ? "https://cost.huestimates.app/api/controller"
  //   : "http://localhost:7700/controller";
Vue.prototype.$pyUrl = "https://costpy.huestimates.app/";

// configure router
const router = new VueRouter({
  // mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  const metaauth = to.matched.find(record => record.meta.requiresAuth);

  if (metaauth !== undefined) {
    let user = store.state.user;
    if (user === null) {
      next({
        path: "/unlock"
      });
    } else next();
  } else next();
});

Vue.filter("formatNumber0", function(value) {
  return numeral(value)
    .format("0,0")
    .replace(",", " "); // displaying other groupings/separators is possible, look at the docs
});
/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: h => h(App),
  router,
  store
});
