import axios from "axios";
import Vue from "vue";
import {api} from "./helper";
const client = axios.create({
  baseURL: api,
  withCredentials: false
});


function GetUser(id) {
  const params = {
    id: id
  };
  return client.get("user.php", { params });
}

export {
  GetUser
};
