<template>
  <div>
    <notifications></notifications>
    <div>
      <top-navbar></top-navbar>
      <router-view name="header"></router-view>
      <div style="padding-left: 20px; padding-right: 20px; margin-top: -30px">
        <router-view></router-view>
      </div>
      
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import store from "../../../store";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import TopNavbar from "./DemoTopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import { SlideYDownTransition, ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu,
    SlideYDownTransition,
    ZoomCenterTransition,
  },
  methods: {},
  mounted() {
    // console.log(this.$store.state.user)
    // let docClasses = document.body.classList;
    // let isWindows = navigator.platform.startsWith('Win');
    // if (isWindows) {
    //   // if we are on windows OS we activate the perfectScrollbar function
    //   // initScrollbar('sidebar');
    //   // initScrollbar('sidebar-wrapper');
    //   docClasses.add('perfect-scrollbar-on');
    // } else {
    //   docClasses.add('perfect-scrollbar-off');
    // }
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
