<template>
  <navbar>
    <div class="navbar-wrapper">
      <span v-html="pageTitle"></span>

    </div>

  </navbar>
</template>
<script>
import { RouteBreadCrumb, Navbar, NavbarToggleButton } from "src/components";
export default {
    components: {
    Navbar,
    NavbarToggleButton,
  },
  computed: {

    pageTitle() {
      return this.$route.meta.title;
    }
  },
  data() {
    return {
      activeNotifications: false,
    };
  },
};
</script>
<style>
</style>
