<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul class="footer-menu">
          
        </ul>
      </nav>
      <div class="copyright" ref="ssl">
        &copy; {{year}}, Developed by
        <a href="https://www.huestimates.com/">HUEstimates</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  // mounted () {
  //   let script1 = document.createElement('script');
  //   script1.setAttribute('type', 'text/javascript');
  //   let text1 = '//<![CDATA[' + '\n' + 
  //     'var tlJsHost = ((window.location.protocol == "https:") ? "https://secure.trust-provider.com/" : "http://www.trustlogo.com/");' +
  //     'document.write(unescape("%3Cscript src=\'" + tlJsHost + "trustlogo/javascript/trustlogo.js\' type=\'text/javascript\'%3E%3C/script%3E")); \n //]]>';
  //   let inlineScript1 = document.createTextNode(text1);
  //   script1.appendChild(inlineScript1);

  //   let script2 = document.createElement('script');
  //   script2.setAttribute('type', 'text/javascript');
  //   script2.setAttribute('language', 'javascript');
  //   let text2 = 'TrustLogo("https://www.positivessl.com/images/seals/positivessl_trust_seal_md_167x42.png", "POSDV", "none");';
  //   let inlineScript2 = document.createTextNode(text2);
  //   script2.appendChild(inlineScript2);
  //   // Append script
  //   console.log(script1)
  //   console.log(script2)
  //   this.$refs.ssl.insertAdjacentElement('afterend', script1);
  //   this.$refs.ssl.insertAdjacentElement('afterend', script2);

  // }
};
</script>
<style>
</style>
