import store from "../store";
import { EncryptAES, DecryptAES } from "./crypto";

import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

function SetUser(user) {
  if (user === null) {
    localStorage.removeItem("cusr");
  } else {
    localStorage.setItem(
      "cusr",
      EncryptAES(JSON.stringify(user), LoadCookie("ctoken"))
    );
    store.state.user = user;
  }
  return;
}

function LoadUser() {
  if (localStorage.getItem("cusr") !== null)
    try {
      return JSON.parse(
        DecryptAES(localStorage.getItem("cusr"), LoadCookie("ctoken"))
      );
    } catch (err) {
      console.log(err)
      return null;
    }
}

function SetCookie(name, value) {
  $cookies.set(name, value, "30d");
  return;
}

function LoadCookie(name) {
  if (Vue.$cookies.isKey(name)) return Vue.$cookies.get(name);
}

function SaveStatePlugin(store) {
  store.subscribe((mutation, state) => {
    SetUser(state.user);
  });
}

export { SetUser, LoadUser, SaveStatePlugin, SetCookie, LoadCookie };
