import Vue from "vue";
import Vuex from "vuex";
import { LoadUser, SaveStatePlugin } from "./services/localstate";

Vue.use(Vuex);

const curruser = LoadUser();

export default new Vuex.Store({
  plugins: [SaveStatePlugin],
  state: {
    user: curruser
  },
  mutations: {
    UPDATE_USER(state, user) {
      state.user.username = user.username;
      state.user.id = user.id;
      state.user.role = user.role;
    },
    LOGOUT_USER(state) {
      state.user = null;
    }
  },
  actions: {
    updateProfileData({ commit }, user) {
      commit("UPDATE_USER", user);
    },
    logout({ commit }) {
      commit("LOGOUT_USER");
    }
  },
  getters: {
    username: state => {
      if (!state.user) return "No token";
      else return state.user.username;
    },
    userid: state => {
      if (!state.user) return null;
      else return state.user.id;
    },
    cpvisible: state => {
      if (!state.user) return true;
      else return state.user.role[0] == "1";
    },
    revisible: state => {
      if (!state.user) return true;
      else return state.user.role[1] == "1";
    },
    lvisible: state => {
      if (!state.user) return false;
      else return state.user.role[2] == "1";
    },
    vinvisible: state => {
      if (!state.user) return true;
      else return state.user.role[3] == "1";
    },
    demouser: () =>  { return "24346373-fb97-4001-8765-3570a685e46c"; }
  }
});
